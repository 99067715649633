import React, { useState } from 'react'
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'

import ReferaLogo from '_assets/svgs/refera-logo-negative.svg'
import ReferaLogoWhite from '_assets/svgs/refera-logo.svg'
import HeaderWorkerImg from '_assets/images/landing-page-rs-b2c/header-worker.webp'

import QuarterCircleImg from '_assets/images/landing-page-rs-b2c/quarter-circle.webp'
import WhatsappIcon from '_assets/icons/ic-whatsapp.svg'
import FacebookIcon from '_assets/icons/ic-facebook.svg'
import InstagramIcon from '_assets/icons/ic-instagram.svg'
import YoutubeIcon from '_assets/icons/ic-youtube.svg'
import LinkedinIcon from '_assets/icons/ic-linkedin.svg'

import { navigate } from '@reach/router'
import { DraggableList } from './components/draggable-list'
import { AnimatedNumber } from './components/animated-number'
import { CustomizedAccordions } from './components/accordion'
import { SERVICES_LIST, STEPS_LIST, BENEFITS_LIST } from './constants'

import Svg from '_/components/svg'

import classNames from 'classnames'

import useStyles, { BackgroundImage } from './styles'
import { PWA_LINK } from '_/utils/constants'

export const LandingPageB2C = () => {
  const styles = useStyles()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('640'))

  const [expandedBenefit, setExpandedBenefit] = useState(null)

  const handleGoToServiceForm = () => {
    navigate('/solicitar-manutencao/refera-b2c-rs')
  }

  const handleGoToPWA = () => {
    window.open(`${PWA_LINK}/refera-b2c-rs`, '_blank', 'noopener,noreferrer')
  }

  return (
    <Grid className={styles.container}>
      <Grid className={styles.intro}>
        <BackgroundImage image={HeaderWorkerImg} alt="Prestador de serviços sorrindo" />
        <Grid className={styles.introWrapper}>
          <Grid className={styles.introContent}>
            <Typography className={styles.introTitle}>
              Refera e você, juntos pela reconstrução do Rio Grande do Sul
            </Typography>
            <Typography className={styles.introDescription}>
              Conte com a maior plataforma de manutenções do Brasil para reconstruir seu lar com
              qualidade, garantia e preço justo.
            </Typography>
            <Grid className={styles.buttonsContainer}>
              <button
                type="button"
                onClick={handleGoToServiceForm}
                className={classNames(styles.button, styles.whiteButton)}
              >
                Solicite um orçamento sem compromisso
              </button>
              <button
                type="button"
                onClick={handleGoToPWA}
                className={classNames(styles.button, styles.orangeButton)}
              >
                Ver minhas solicitações abertas
              </button>
            </Grid>
          </Grid>
          <Grid className={styles.introImgContainer}>
            <Svg
              icon={mobile ? ReferaLogo : ReferaLogoWhite}
              ariaLabel="Logo da Refera"
              className={styles.introReferaLogo}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={styles.services}>
        <Grid className={styles.servicesWrapper}>
          <Typography className={classNames(styles.title, styles.servicesTitle)}>
            Solicite diversos serviços de manutenção em um só lugar
          </Typography>
          <Grid className={styles.servicesList}>
            {SERVICES_LIST.map(item => (
              <Grid key={item.text} className={styles.serviceItem}>
                <img src={item.icon} alt={item.text} className={styles.serviceIcon} />
                <Typography className={styles.serviceText}>{item.text}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid className={styles.steps}>
        <Grid className={styles.stepsWrapper}>
          <Typography className={classNames(styles.title, styles.stepsTitle)}>
            Seu serviço em 7 passos simples
          </Typography>
          <DraggableList>
            {STEPS_LIST.map(({ title, description, extraInfo }, index) => (
              <Grid key={title} className={styles.stepItem}>
                {index < STEPS_LIST.length - 1 && (
                  <div
                    className={classNames(
                      styles.timeline,
                      index === 0 && styles.timelineFirstCircle
                    )}
                  >
                    {index === 0 && <div className={styles.timelineCircle} />}
                    <div className={styles.timelineCircle} />
                  </div>
                )}
                <Grid className={styles.timelineInfoWrapper}>
                  <Typography className={classNames(styles.title, styles.timelineInfoTitle)}>
                    {title}
                  </Typography>
                  <Typography className={styles.timelineInfoDescription}>{description}</Typography>
                  {extraInfo && <div className={styles.timelineExtraInfoSeparator} />}
                  <Typography className={styles.timelineExtraInfo}>
                    {extraInfo?.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </DraggableList>
        </Grid>
      </Grid>

      <Grid className={styles.stats}>
        <Grid className={styles.statsWrapper}>
          <Typography className={classNames(styles.title, styles.statsTitle)}>
            A Refera em números
          </Typography>
          <Grid className={styles.statsList}>
            <Grid className={styles.statsItem}>
              <AnimatedNumber start={0} end={1000} />
              <Typography className={styles.statsDescription}>
                Prestadores de serviços ativos
              </Typography>
            </Grid>
            <Grid className={styles.statsItem}>
              <AnimatedNumber start={0} end={70} timeout={10} />
              <Typography className={styles.statsDescription}>
                Cidades em que estamos presentes
              </Typography>
            </Grid>
            <Grid className={styles.statsItem}>
              <AnimatedNumber start={40000} end={80000} numberToAdd={30} />
              <Typography className={styles.statsDescription}>
                Serviços <br /> finalizados
              </Typography>
            </Grid>
          </Grid>
          <img src={QuarterCircleImg} className={styles.quarterCircleImg} alt="decoration" />
        </Grid>
      </Grid>

      <Grid className={styles.benefits}>
        <Grid className={styles.benefitsWrapper}>
          <Grid className={styles.benefitsDescriptionContainer}>
            <Typography className={styles.benefitsDescription}>
              Muito mais que uma experiência de excelência, queremos ser a garantia de um pouco de
              paz nesse momento tão delicado para as famílias do Rio Grande do Sul.
            </Typography>
            <Grid className={styles.benefitsTitleButtonWrapper}>
              <Typography
                className={classNames(styles.title, styles.statsTitle, styles.benefitsTitle)}
              >
                A contratação dos nossos serviços incluem
              </Typography>
              <button
                type="button"
                onClick={handleGoToServiceForm}
                className={classNames(styles.button, styles.blueButton)}
              >
                Solicite um orçamento sem compromisso
              </button>
            </Grid>
          </Grid>
          <Grid className={styles.benefitsContent}>
            <Grid className={styles.benefitsList}>
              {BENEFITS_LIST.map(({ text, description }, index) => (
                <CustomizedAccordions
                  title={text}
                  description={description}
                  open={() => setExpandedBenefit(index)}
                  expanded={expandedBenefit === index}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={styles.ending}>
        <Grid className={styles.endingWrapper}>
          <Grid className={styles.endingLeft}>
            <Typography className={classNames(styles.title, styles.statsTitle, styles.endingTitle)}>
              Conte com a Refera nessa reconstrução
            </Typography>
          </Grid>
          <Grid className={styles.endingRight}>
            <Svg icon={ReferaLogo} ariaLabel="Logo da Refera" className={styles.referaLogoBlue} />
            <Typography className={styles.endingMessage}>
              O que você precisa, com a agilidade e segurança que o momento exige.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <footer className={styles.footer}>
        <Grid className={styles.footerWrapper}>
          <div className={styles.logoContainer}>
            <Svg icon={ReferaLogoWhite} ariaLabel="Logo da Refera" className={styles.referaLogo} />
            <Typography className={styles.logoText} style={{ color: 'white' }}>
              A Plataforma
              <br />
              de manutenções
            </Typography>
          </div>
          <div className={styles.footerGroup}>
            <span className={styles.footerGroupTitle}>Contato</span>
            <div className={styles.contactButtonsContainer}>
              <a
                href="https://wa.me/5548991813893"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Whatsapp"
              >
                <Svg
                  icon={WhatsappIcon}
                  ariaLabel="Logo do Whatsapp"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.facebook.com/AppRefera/"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Facebook"
              >
                <Svg
                  icon={FacebookIcon}
                  ariaLabel="Logo do Facebook"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.instagram.com/apprefera/"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Instagram"
              >
                <Svg
                  icon={InstagramIcon}
                  ariaLabel="Logo do Instagram"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.youtube.com/@refera9876/featured"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Youtube"
              >
                <Svg
                  icon={YoutubeIcon}
                  ariaLabel="Logo do Youtube"
                  className={styles.contactIcons}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/refera/"
                target="_blank"
                rel="noreferrer"
                className={styles.contactButton}
                aria-label="Contato do Linkedin"
              >
                <Svg
                  icon={LinkedinIcon}
                  ariaLabel="Logo do Linkedin"
                  className={styles.contactIcons}
                />
              </a>
            </div>
          </div>
        </Grid>
      </footer>
    </Grid>
  )
}
